import {
  ADDITIONAL_INFO_NAME_KEY,
  AdditionalInfo,
  MainSegment,
  SeasonContract,
  Supplier,
  SupplierContact,
  SupplierDocument,
  employmentCategory,
  revenueCategory,
  Feedback
} from 'types/Supplier'
import { differenceInDays, parse } from 'date-fns'

import { Fira2020 } from 'types/Classification'

/**
 * group array of objects by object key
 */
export function groupBy<T, K>(list: T[] | undefined, getKey: (item: T) => K): T[][] {
  if (!list) {
    return []
  }
  const map = new Map<K, T[]>()
  list.forEach((item) => {
    const key = getKey(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return Array.from(map.values())
}

/**
 * convert currency values to string.
 * Strips away cents
 * @param number
 */
export const currency = (number: number | null | undefined): string => {
  const amount = number || 0
  return new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(amount)
}

/**
 * format bisnode date
 * @param date yyyyMMdd
 */
export const formatBisnodeDate = (date: string | undefined) => {
  if (!date) {
    return ''
  }
  return new Intl.DateTimeFormat('fi-FI').format(parse(date, 'yyyyMMdd', new Date()))
}

/**
 * format M-Files date
 * @param date yyyy-MM-dd
 */
export const formatMfilesDate = (date: string) => {
  if (!date) return ''
  return new Intl.DateTimeFormat('fi-FI').format(parse(date, 'yyyy-MM-dd', new Date()))
}

/**
 * check if date is within 2 months
 */
export const isRecentlyAdded = (addedAt?: string) => {
  if (!addedAt) return false
  return differenceInDays(new Date(), new Date(addedAt)) < 60
}

/**
 * format ISO8601 date
 * @param date format e.g. new Date().toISOString()
 */
export const formatIsoDate = (date: string | undefined) => {
  if (!date) return ''
  return new Intl.DateTimeFormat('fi-FI').format(new Date(date))
}

const revenueCategories: { [key in revenueCategory]: string } = {
  '00': 'LUOKITTELEMATON',
  '01': 'LUOKITTELEMATON',
  '02': 'EI KÄSITTEELLINEN',
  '03': 'ALLE 200 000',
  '04': '200 000 –  399 999',
  '05': '400 000 –  999 999',
  '06': '1 000 000 – 1 999 999',
  '07': '2 000 000 – 9 999 999',
  '08': '10 000 000 – 19 999 999',
  '09': '20 000 000 –'
}
const employmentCategories: { [key in employmentCategory]: string } = {
  '00': 'LUOKITTELEMATON',
  '01': 'ALLE 5',
  '02': '5 – 9',
  '03': '10 – 19',
  '04': '20 –  49',
  '05': '50 –  99',
  '06': '100 – 249',
  '07': '250 – 499',
  '08': '500 – 1000',
  '09': 'YLI 1000'
}

export const getRevenueCategory = (category?: revenueCategory) => {
  if (!category) {
    return ''
  }
  return revenueCategories[category]
}
export const getEmploymentCategory = (category?: employmentCategory) => {
  if (!category) {
    return ''
  }
  return employmentCategories[category]
}

/**
 * Instantiate new 'empty' supplier instance
 */
export const createSupplierInstance = (businessId?: string): Supplier => ({
  businessId: businessId || '',
  supplierCategory: 'pending'
})

type UpdatedData = SupplierDocument | Supplier
/**
 * update supplier object fields.
 * overwrite baseData with updatedData using spread syntax
 */
const updateSupplierObject = (baseData: Supplier, updatedData: UpdatedData) => {
  const supplier: Supplier = {
    ...baseData,
    ...updatedData
  }
  return supplier
}

/**
 * update or append to dataset
 * @param suppliers data to be updated
 * @param dbSuppliers updated data
 */
export const updateDataset = (
  suppliers: Supplier[],
  dbSuppliers: UpdatedData[],
  fira2020: Fira2020
) => {
  const updatedData: Supplier[] = [...suppliers]
  dbSuppliers.forEach((dbSupplier) => {
    const supplier: Supplier = {
      ...dbSupplier,
      fira2020AllValues: dbSupplier.fira2020?.flatMap((item) => fira2020[item]?.names || [])
    }
    const index = updatedData.findIndex(({ businessId }) => businessId === supplier.businessId)
    if (index === -1) {
      updatedData.push(supplier)
      return
    }
    updatedData[index] = updateSupplierObject(updatedData[index], supplier)
  })
  return updatedData
}

export const isBoolean = (value: boolean | undefined) => {
  return typeof value === 'boolean'
}

export const createTimestamp = () => {
  return new Date().toISOString()
}

export const calculatePercent = (part: number, total: number) => {
  return Math.round((part / total) * 100)
}

/**
 * codes that indicate bankruptcy.
 * applies to field: defaultCode
 */
const bankruptcyIndicators: string[] = [
  'KHI', // Velallisen konkurssihakemus
  'KHV', // Velkojan konkurssihakemus
  'KJS', // Konkurssihakemus jäänyt sillensä
  'KHH', // Konkurssihakemus hylätty
  'KKV', // Konkurssiin asettaminen
  'KHP', // Konkurssi peruttu asettamisen jälkeen
  'KS', // Konkurssiasiassa sovinto
  'KR', // Konkurssi rauennut
  'KRS', // Konkurssihakemus rauennut saneerauksen johdosta
  'KVS', // Konkurssi julkisselvitykseen
  'KVA', // Konkurssivalvonta
  'KJV' // Konkurssijakoluettelon vahvistaminen
]

/**
 * check if supplier is a 'risk'
 */
export const isBankruptcyRisk = (defaultCode?: string) => {
  if (!defaultCode) return false
  return bankruptcyIndicators.includes(defaultCode)
}

export const parseAdditionalInfo = (additionalInfo?: string): AdditionalInfo[] => {
  if (!additionalInfo) {
    return []
  }
  try {
    return JSON.parse(additionalInfo) as AdditionalInfo[]
  } catch (error) {}
  return []
}

const parseAdditionalInfoContacts = (additionalInfoStr?: string): SupplierContact[] => {
  const additionalInfo = parseAdditionalInfo(additionalInfoStr)
  if (!additionalInfo.length) {
    return []
  }
  const supplierContacts = additionalInfo.find((a) => a.name === ADDITIONAL_INFO_NAME_KEY)
  if (!supplierContacts) {
    return []
  }
  try {
    return JSON.parse(supplierContacts.value) as SupplierContact[]
  } catch (error) {}
  return []
}

/**
 * determine which contacts to display, contacts can be edited and stored in firestore.
 * Additionally additionalInfo can contain contacts from sitemanager.
 * If no contacts are stored in firestore, the sitemanager contacts will be displayed
 */
export const displayContacts = (contacts?: SupplierContact[], additionalInfo?: string) => {
  if (contacts && contacts.length > 0) {
    return [...contacts]
  }
  const sitemanagerContacts = parseAdditionalInfoContacts(additionalInfo)
  return sitemanagerContacts
}

// append https to url if not exist
export const withHttps = (url?: string) => {
  if (!url) {
    return ''
  }
  return !/^https?:\/\//i.test(url) ? `https://${url}` : url
}

// validate if binding season-contract releates to the selected main segment
export const isSeasonContractInSegment = (
  selectedLinja?: MainSegment[],
  seasonContracts?: SeasonContract[]
) => {
  if (!selectedLinja || !seasonContracts || selectedLinja.length === 0) {
    return false
  }
  return seasonContracts.some(({ bindingType }) => {
    const segments = bindingType.split('; ')
    const linja: string[] = [...selectedLinja]
    // bindingType contains 'Liike- ja toimitilarakentaminen' as 'Liike- ja toimitilat'
    if (linja.includes(MainSegment.CommercialConstruction)) {
      linja.push('Liike- ja toimitilat')
    }
    return segments.some((segment) => linja.includes(segment))
  })
}

/**
 * filters out feedbacks which are given over two years ago
 */
export const filterOutOldFeedbacks = (feedbacks: Feedback[] | undefined) => {
  if (!feedbacks) {
    return []
  }
  const today = new Date()
  const dateTwoYearsAgo = new Date(today.setFullYear(today.getFullYear() - 2))
  return feedbacks.filter((feedback) => new Date(feedback.timestamp) > dateTwoYearsAgo)
}
